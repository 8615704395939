.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.navbar {
  background-color: #ffffff;
  height: 60px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  align-content: center;
}

.page-wrapper .content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;
}

.sidemenu {
  background-color: #f4f6fa;
  flex-grow: 0;
  width: 220px;
}

.sidemenu + div {
  width: 220px;
}

.top-containers {
  width: 220px;
}

.menus {
  z-index: 9;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
}

.logo {
  align-content: center;
  height: 100%;
  justify-content: center;
  display: flex;
}

.logo img {
  width: 160px;
  cursor: pointer;
}

.page-content {
  padding: 1vh;
  background-color: #fbfbfb;
  flex-grow: 10;
  align-self: baseline;
}

.profile-picture {
  width: 40px;
  height: 40px;
}

.notifications-group img,
.profile-picture img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.profile-arrow {
  width: 20px;
  margin-left: 5px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  width: 4px;
  height: 1.25rem;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.arrow span {
  top: 0.5rem;
  position: absolute;
  width: 0.5rem;
  height: 0.1rem;
  background-color: #122045;
  display: inline-block;
  transition: all 0.2s ease;
}

.arrow span:first-of-type {
  left: 0;
  transform: rotate(45deg);
}

.arrow span:last-of-type {
  right: 0;
  transform: rotate(-45deg);
}

.arrow.active span:first-of-type {
  transform: rotate(-45deg);
}

.arrow.active span:last-of-type {
  transform: rotate(45deg);
}

.profile-group {
  display: flex;
  position: relative;
  cursor: pointer;
}

.profile-container {
  min-width: 280px;
  position: absolute;
  top: 45px;
  right: 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(18, 32, 69, 0.1);
}

.profile-container header {
  height: 75px;
  border-bottom: 1px solid #eceff5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
}

.profile-container .email {
  color: #b2b6bf;
  font-size: 14px;
  font-family: 'Montserrat';
}

.profile-container .name {
  color: #5a6774;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Mangueira', 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.profile-container section {
}

.profile-container section li.current {
  font-weight: 600;
}

.profile-container section li {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 15px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Montserrat';
}

.profile-container section li:hover {
  background-color: #f4f6fa;
}

.profile-container section li:last-child {
  border-top: 1px solid #eceff5;
}

.menu-button {
  padding-left: 40px !important;
}

.menu-icon {
  min-width: 30px !important;
}

.menu-button.active {
  background-color: #e6edfd;
  padding-left: 35px !important;
  border-left: 5px solid #3851a4;
}

.number-circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #3851a4;
  color: #ffffff;
  text-align: center;
  font: 12px 'Montserrat', sans-serif;
  line-height: 20px;
}

.menus-list {
  width: 220px;
}

.notifications-group {
  margin-right: 16px;
  position: relative;
}

.notifications-group .number-circle {
  position: absolute;
  top: 0;
  left: 16px;
  border: 1px solid #ffffff;
  background-color: #e31313;
  color: #ffffff;
}

.notification-container {
  display: flex;
  width: 500px;
  padding: 24px;
}

.notification-container.new-notification {
  background-color: #e6edfd;
}

.notification-container .image {
  width: 40px;
  height: 40px;
}

.notification-container .image img {
  width: 40px;
  height: 40px;
}

.notification-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
}

.notification-date {
  margin-top: 10px;
  display: flex;
  font-size: 12px;
  font-weight: 400;
}

.notification-date-container {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.notification-date-value {
  font-weight: 600;
  font-size: 14px;
}

.notifications-container {
  z-index: 10000;
  width: 500px;
  min-height: 100px;
  position: absolute;
  top: 45px;
  right: 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgb(18 32 69 / 30%);
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* width */
.top-containers ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.top-containers ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #b0b0b0;
  border-radius: 10px;
}

/* Handle */
.top-containers ::-webkit-scrollbar-thumb {
  background: rgb(143, 143, 143);
  border-radius: 10px;
}

/* Handle on hover */
.top-containers ::-webkit-scrollbar-thumb:hover {
  background: rgb(114, 113, 113);
}

.notifications-container > div {
  border-bottom: 1px solid #d8dbe1;
}

.notifications-container > div:last-child {
  border-bottom: none;
}

.no-results {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  padding-top: 50px;
}
