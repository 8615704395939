// sass-lint:disable no-duplicate-properties
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

/**
 * Return proper color depending on background.
 *
 * @param  {color} $color The background color
 * @param  {color} $light The text if BG is light
 * @param  {color} $dark  The text if BG is dark
 * @return {color}        The color of the text depending on background.
 */
@function color-calc($color, $light, $dark) {
  @if (lightness($color) > 50) {
    // Lighter backgorund, return dark color
    @return $light;
  } @else {
    // Darker background, return light color
    @return $dark;
  }
}

@mixin custom-dropdown {
  background-color: #fff;
  width: 100%;
  z-index: 110;
  overflow-y: auto;
  max-height: 200px;
  border-radius: 3px;
  box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7);
}
