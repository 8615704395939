.list-checklist {
  font-size: 26px;

  .list-item {
    padding: 35px 0 35px 90px;
    border-bottom: 1px solid #ddd;

    &:first-child {
      padding-top: 0;
      background-position: top 10px left;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  @media (max-width: $breakpoint-small) {
    font-size: 14px;
    margin: 0 auto;
    max-width: 250px;

    .list-item {
      background-position: top center;
      background-size: 100px;
      padding: 120px 20px 20px;
      margin-bottom: 20px;
      text-align: center;

      &:first-child {
        padding-top: 120px;
        background-position: top center;
      }
    }
  }
}

.list-minimal {
  font-size: 16px;

  .list-item {
    margin-bottom: 0.5em;

    // We have icons inside every now and then
    display: flex;
    align-items: flex-start;
  }

  a:hover {
    opacity: 0.8;
  }

  .icon {
    width: 24px;
    margin-right: 20px;
  }
}

.list-large-connected {
  display: flex;
  justify-content: space-between;

  .list-item {
    text-align: center;
    width: 250px;
  }

  .item-icon {
    $border-size: 20px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-white;
    box-shadow: 0 0 0 $border-size $color-text, 0 0 0 #{$border-size + 2px} $color-primary;
    margin: #{$border-size} auto #{$border-size + 35px};
    padding: 10px;

    .icon {
      font-size: 80px;
      margin-top: 30px;
    }
  }

  .item-description {
    font-size: 26px;
  }

  @media (max-width: $breakpoint-small) {
    display: block;

    .list-item {
      max-width: 190px;
      display: block;
      margin: 30px auto 60px;
    }

    .item-icon {
      width: 70px;
      height: 70px;
      margin: 30px auto;

      .icon {
        font-size: 60px;
        margin-top: 25px;
      }
    }

    .item-description {
      font-size: 16px;
      line-height: 1.25;
    }
  }
}

.list-purchases {
  .list-item {
    padding: 20px 0 20px 20px;
    border-bottom: 1px solid $color-border;
    justify-content: space-between;
    display: flex;
  }

  .item-delete {
    margin-left: 10px;

    & > div {
      cursor: pointer;
      height: 28px;
      margin-top: -10px;
      color: $color-text-muted;
    }

    & > div:hover {
      color: $color-status-inactive;
    }
  }

  .item-warning {
    color: $color-primary;
  }

  .item-products {
    flex: 1;
  }

  .item-product,
  .item-description,
  .item-validity,
  .item-timespan {
    display: block;
  }

  .item-product.inline {
    display: inline;
  }

  .item-product {
    font-size: 26px;
  }

  .item-description,
  .item-validity,
  .item-timespan {
    font-size: 18px;
  }

  .item-cost {
    text-align: right;
  }

  .item-price {
    font-size: 24px;
    font-weight: bold;
    color: $color-primary;
  }

  @media (max-width: $breakpoint-small) {
    .item-product {
      font-size: 16px;
    }

    .list-item {
      padding-left: 0;
      padding-right: 0;
    }

    .item-description,
    .item-validity,
    .item-timespan {
      font-size: 16px;
    }
  }
}

.list-purchases.print-purchases {
  .list-item {
    padding: 10px;
    border: none;

    .item-container {
      border: 1px solid $color-border;
      padding: 20px;
      width: 100%;

      .item-product {
        text-align: center;
      }

      table {
        margin-bottom: 0;
        margin-top: 20px;

        tr {
          display: flex;
          border: none;
        }

        td {
          padding: 1px;
          text-overflow: clip;
          overflow: hidden;
          white-space: nowrap;

          &:first-child {
            flex-basis: 40%;
            background: $color-white;
            color: $color-text;
            @media (max-width: $breakpoint-large) {
              flex-basis: 50%;
            }
          }

          &:last-child {
            flex-basis: 50%;
          }
        }
      }
    }
  }
}

.list-types {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 60px 0;

  .list-item {
    flex-basis: calc(100% / 3 - 80px);
    flex-grow: 0;
    flex-shrink: 0;
  }

  .item-image {
    margin-bottom: 40px;
    height: 40px;
  }
}

.payment-picker {
  margin-bottom: 20px;

  .input-group {
    input,
    input:hover,
    &-radio,
    &-radio:hover,
    .react-select__control {
      border: 2px solid $color-primary;
      border-radius: 5px;

      &:hover {
        border-color: lighten($color-primary, 20%);
      }
    }

    .multi-select-input.searchable {
      height: 60px;
    }

    .react-select__control {
      line-height: 1.3;
      padding: 0.5em 10 xp 0.5em 10px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background: white;
      width: 100%;
      outline: none;
      font-size: 20px;
      height: 65px;
    }

    .react-select__input input {
      height: 42px;
    }
  }

  .box-summary-note {
    margin: 10px 0 20px 0;
  }

  .payment-summary {
    max-width: 620px;

    @media (min-width: $breakpoint-small) {
      margin-left: 40px;
    }

    .box-summary-empty {
      padding: 80px 0;
      text-align: center;
      font-size: 22px;
      margin: 15px 0 10px 0;
      border: 2px solid $color-primary;
      border-radius: 5px;

      .icon {
        color: #ccc;
        font-size: 150px;
      }
    }

    .button.button-add {
      width: 100%;
      margin: 5px 0;
      text-align: center;
    }

    .box-summary-header .icon {
      max-height: 40px;
    }

    .cart-summary-title .custom-button-holder {
      display: inline-block;
      float: right;

      .icon {
        float: left;
        color: #fff;
      }

      img {
        width: 24px;
        margin-top: 0px;
      }

      .button {
        height: 34px;
        width: 160px;
        font-size: 18px;
        padding: 5px;
      }
    }

    .box-summary-container {
      border: 2px solid $color-primary;
      margin: 14px 0;
      padding: 10px;
      border-radius: 5px;

      .box-summary-add {
        display: inline-block;
        cursor: pointer;

        .icon {
          height: 28px;
          margin-right: 10px;
        }

        div {
          display: inline-block;
          vertical-align: bottom;
        }

        &:hover .icon {
          color: $color-primary;
        }
      }

      .box-summary-final {
        font-size: 20px;
        text-align: right;
      }
    }
  }
}

/*NEWS LIST PAGE*/

.site-content .news-container {
  margin: 50px 150px;
  text-align: justify;
}

.news-item {
  transition: box-shadow 0.3s;
  clear: both;
  margin-bottom: 15px;
  margin-top: 25px;
  background-color: #f2f2f25c;
  border-radius: 5px;
  display: flex;
  border: 1px solid #e7e7e7;

  &:after {
    display: block;
    clear: both;
    content: '';
  }

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }

  .thumbnail {
    position: relative;
    order: 1;
    flex: 1 2 auto;
    min-width: 320px;
    display: inline-flex;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      width: 100%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  .info {
    padding: 15px 20px 20px;
    position: relative;
    order: 2;
    flex: 3 1 auto;

    .headline {
      font-size: 1.375rem;
      line-height: 1.2;
      margin-bottom: 10px;
    }

    .description {
      font-size: 0.95rem;
      line-height: 1.2;
      margin-top: 12px;
      padding-bottom: 30px;
    }

    .published {
      position: absolute;
      left: 27px;
      right: 20px;
      bottom: 16px;
      font-style: italic;
      font-size: 0.7875rem;
    }
  }
}

@media (min-width: $breakpoint-huge) {
  .news-item {
    .thumbnail {
      width: 420px;
      flex: 1 0 auto;
    }
  }
}

@media (max-width: $breakpoint-large) {
  .site-content {
    .news-container {
      margin: 50px 50px;
    }

    .news-layout {
      .widget-content {
        display: none;
      }
    }

    header {
      &.news-page {
        margin: 50px 50px 0px;
      }
    }
  }
}

@media (max-width: $breakpoint-medium) {
  .site-content {
    .news-container {
      margin: 30px 20px;
    }

    header {
      &.news-page {
        margin: 30px 20px 0px;
      }
    }
  }
  .news-item {
    .thumbnail {
      min-width: 220px;
    }
  }
}

@media (max-width: $breakpoint-small) {
  .site-content {
    .news-container {
      margin: 30px 5px;
    }

    header {
      &.news-page {
        margin: 30px 5px 0px;
      }
    }
  }
  .news-item {
    border-radius: 0px;
    flex-direction: column;
    border: none;

    .thumbnail img {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}
