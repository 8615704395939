.chat-page-container {
  height: 100%;
  padding: 15px 20px;
  background-color: #fbfbfb;
  color: #3f3a38;
  font-weight: 600;
}

.chat-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: 'Mangueira', 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.divider {
  text-align: center;
  padding: 10px;
  margin: 0;
  font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #5a6774;
}

.divider.filled span {
  background: #eceff5;
  border-radius: 100px;
  padding: 3px 15px;
}
