$roadly-color-primary: #e90023;
$roadly-color-green-weak: #4ed76082;
$roadly-color-green: #4ed760;
$roadly-color-gray-weak: #f2f2f2;
$roadly-color-gray: #cacaca;
$roadly-color-gray-strong: #969696;
$roadly-color-pending: #ff8d00;

.sticky-header {
  overflow: hidden;

  .site-header-main {
    padding-bottom: 10px;
  }

  .site-content {
    height: 100vh;
  }

  .site-header.headroom.headroom--not-top.headroom {
    transform: unset !important;
    transition: unset !important;
    z-index: 50;
  }
}

.routes {
  display: flex;

  .icon-bars {
    position: absolute;
    z-index: 1;
    top: 150px;
    left: 50px;
    background-color: $color-white;
    font-size: 30px;
    border-radius: 5px;
    height: 66px;
    text-align: center;
    line-height: 75px;
    width: 60px;
    cursor: pointer;
  }

  &.site-content {
    padding-top: 100px !important;
  }

  .routes-menu {
    z-index: 10;
    width: 700px;
    background-color: $color-white;
    left: -700px;
    transition: left 1s;
    overflow-y: auto;
    display: none;

    &.menu-open {
      display: block;
    }

    .menu-toll-charges {
      text-align: center;
      margin: 0 15px;
    }

    .collapse-icon {
      font-size: 50px;
      cursor: pointer;
      position: absolute;
      left: 650px;
    }

    .rt-tr-group {
      cursor: pointer;
    }

    .date-picker-container {
      .input-group {
        border-radius: 5px;
        background: $color-white;
        min-height: unset;
        height: 40px;
        border: 1px solid lightgrey;
        width: 285px;

        label {
          display: none;
        }

        div input {
          border: none;
          padding-left: 15px;
          height: 30px;
          padding-bottom: 0.7em;
        }

        .DayPicker {
          z-index: 100;
          position: absolute;
          background: $color-white;
          border-left: 1px solid #cacaca;
          border-right: 1px solid #cacaca;
          border-bottom: 1px solid #cacaca;
        }
      }
    }

    .menu-info-container {
      padding: 10px;
      display: flex;
      border-bottom: 1px solid $roadly-color-gray;
      border-top: 1px solid $roadly-color-gray;
      background-color: $color-white;

      .vehicle-info {
        margin: 10px 0;
        width: 250px;

        div {
          margin: 10px 0;
        }
      }

      .routes-selection {
        display: flex;
        flex-direction: row;
        margin-right: 35px;
        flex-wrap: wrap;
        justify-content: flex-end;

        label {
          cursor: pointer;
          margin: 10px 5px;
        }

        input[type='checkbox'] {
          cursor: pointer;
          margin-right: 5px;
        }
      }
    }

    .menu-info-container.detailedMap {
      border-top: none;
    }
  }

  .routes-menu.detailedMap {
    width: 400px;
    left: -400px;
  }
}
