// Allowing to run foundation.js file
meta.foundation-data-attribute-namespace {
  font-family: false;
}

// Importing basic HTML element stylings. See it like reset + small stylings
@import 'general-basic';

iframe#printJS {
  //printing fix
  top: 0;
}

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: $font-sans;
  font-size: $global-font-size;
  line-height: 1.5;
  background: white;
  color: $color-text;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
  font-family: $font-heading;

  // Removes the default margins from the headings.
  // Add this customly for entry content if needed.
  margin: 0;
}

blockquote {
  font-style: italic;
}

hr {
  height: 1px;
  border: none;
  width: 100%;
  background: #f0f0f0;
}

// Required WordPress stylings
img.alignright {
  margin: 20px 0 20px 20px;
}

img.alignleft {
  margin: 20px 20px 20px 0;
}

img.aligncenter {
  margin: 20px auto;
}

img {
  max-width: 100%;
  height: auto;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

// Do not show the outline on the skip link target.
#content[tabindex='-1']:focus {
  // sass-lint:disable-line no-ids
  outline: 0;
}

// Make sure embeds and iframes fit their containers.
embed,
iframe,
object {
  max-width: 100%;
}

table {
  font-size: 20px;

  tr {
    border-bottom: 1px solid $color-border;
  }

  th,
  td {
    padding: 1em;
    text-align: left;
    height: 50px;
  }

  td {
    font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 12px;
    background: #ffffff;
    color: #5a6774;
  }

  th {
    cursor: pointer;
    background: #ffffff;
    color: #122045;
    font-size: 12px;
    line-height: 140%;
    font-family: 'Mangueira', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  @media (max-width: $breakpoint-small) {
    th,
    td {
      padding: 20px;
      font-size: 16px;

      &:last-child {
        padding-right: 20px;
      }
    }
  }
}

input,
select,
textarea {
  &::-webkit-input-placeholder {
    color: $color-border;
  }

  &::-moz-placeholder {
    color: $color-border;
  }

  &:-ms-input-placeholder {
    color: $color-border;
  }

  &:-moz-placeholder {
    color: $color-border;
  }
}

.formatted-text {
  padding: 40px;

  ul {
    list-style-type: circle;

    li {
      margin-left: 20px;
    }
  }

  ul.square {
    list-style-type: square;
  }

  ul.decimal {
    list-style-type: decimal;
  }

  h3 {
    margin-top: 30px;
  }
}

.iban-list-container {
  border: 1px solid #cacaca;
  border-radius: 5px;

  .iban-list-ro {
    display: inline-block;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 10px;
    background-color: #eee;
  }
}

@media (max-width: $breakpoint-small) {
  div.heading-main.formatted-text.about-page {
    padding: 15px;
  }
}

@media (max-width: $breakpoint-small) {
  div.formatted-text.registration-steps {
    padding: 10px;
  }
}
