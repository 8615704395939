.user-page-container {
  height: 100%;
  padding: 15px 20px;
  background-color: #fbfbfb;
  color: #3f3a38;
  font-weight: 600;
}

.profile-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: 'Mangueira', 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.profile-image-wrapper {
  margin-bottom: 50px;
}

.profile-image-title {
  margin: 15px 0;
  font-size: 16px;
  font-family: 'Mangueira', 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.profile-image-btns-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.css-ahj2mt-MuiTypography-root.profile-image {
  margin-right: 20px;
}

.profile-image img {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.profile-btns {
  width: 160px;
  height: 42px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Montserrat';
}

.upload-btn {
  background: #3851a4;
  color: #fff;
  border-radius: 2px;
}

.remove-btn {
  background: transparent;
  color: #3851a4;
}

.help-text {
  margin-top: 0;
  font-size: 14px;
  font-weight: 400;
  color: #b2b6bf;
}

.profile-form label {
  margin-bottom: 5px;
  color: #5a6774;
  font-size: 12px;
  font-weight: 400;
}

.profile-form input {
  border-radius: 2px;
}

.profile-form .input-group input,
.profile-form .input-group select,
.profile-form .input-group textarea,
.profile-form .input-group-radio {
  font-size: 12px;
  font-weight: 400;
}

.form-checkbox-container {
  margin-bottom: 20px;
}

.form-checkbox-container p {
  margin: 20px 0 10px;
  font-size: 16px;
}

.form-checkbox-container .input-group-checkbox {
  margin-bottom: 10px;
  align-items: unset;
}

.form-checkbox-container label {
  margin-bottom: 0;
}

.form-btns-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.cancel-btn {
  border: 1px solid #3851a4;
  color: #3851a4;
  background-color: transparent;
}

.save-btn {
  background-color: #3851a4;
  color: #fff;
}

.custumInput input {
  box-sizing: border-box;
}

.custumInput:after,
.custumInput:before {
  content: none !important;
}

.custumInput:hover:not(.Mui-disabled, .Mui-error):before {
  border-bottom: inherit;
}
