@font-face {
  font-family: 'Mangueira';
  src: url('./fonts/Mangueira/Mangueira-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mangueira';
  src: url('./fonts/Mangueira/Mangueira-Medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mangueira';
  src: url('./fonts/Mangueira/Mangueira-SemiBold.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mangueira';
  src: url('./fonts/Mangueira/Mangueira-Bold.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-SemiBold.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Bold.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mangueira {
  font-family: 'Mangueira', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.map-loader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
  width: 100%;
}

.react-datepicker-popper {
  width: 328px;
}
