.timecycle {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.pointer {
  cursor: pointer;
}

.timecycle .pointer div {
  margin: auto;
}

.timecycle-content {
}

.chip {
  background: #3851a4 !important;
  color: #ffffff !important;
}
