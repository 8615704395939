// Global settings
$global-layout-gutter: 20px;
$global-font-size: 12px;
$global-width: 1200px;

// Standart set media queries
$breakpoint-mobile: 480px;
$breakpoint-small: 640px;
$breakpoint-medium: 960px;
$breakpoint-large: 1200px;
$breakpoint-huge: 1800px; // 1640px - row

// Layout
$layout-header-height: 70px;

// Colors:
$color-white: #ffffff;
$color-primary: #3851a4;
$color-primary-hover: #3851a499;
$color-secondary: #a7a7a7;

$color-header: rgba(black, 0.6);
$color-header-solid: #666768;
$color-header-hover: #555657;
$color-text: #494949;
$color-text-muted: #777879;
$color-border: #cacaca;
$color-light: #f2f2f2;

$color-status-active: #53d409;
$color-status-inactive: #ff6262;
$color-status-pending: #ffd800;

$color-header-dropdown: $color-white;

// Fonts
$font-serif: 'Montserrat', 'Book Antiqua', Palatino, Georgia, 'Times New Roman', serif;
$font-sans: 'Montserrat';
$font-heading: $font-sans;

$font-size-heading: 50px;
