.guest-page {
  height: 100vh;
}

.guest-page .image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  background-color: #f4f6fa;
}

.guest-page .title {
  margin-top: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
}

.guest-page .sub-title {
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #122045;
}

.guest-page .link-option {
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}

.guest-page .link {
  color: #3851a4;
  text-decoration: none;
  font-weight: 600;
}

.guest-page .delimiter {
  margin-top: 33px;
  margin-bottom: 33px;
}

.guest-page .divider {
  margin-left: 25px;
}

.guest-page .continue-btn {
  background-color: #3851a4;
  text-transform: uppercase;
  margin-top: 25px;
}

.guest-page .form-footer {
  margin-top: 1vh;
}

.guest-page .form-margin {
  margin-top: 3vh;
}

.guest-page .submit-button button {
  width: 100%;
  height: 5vh;
  background-color: #3851a4;
  color: #ffffff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
}
