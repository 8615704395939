.popup {
  $popup-transition-speed: 0.3s;

  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100; // header is 90, there shouldn't be anything over that.
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    transition: $popup-transition-speed;

    &.is-visible {
      pointer-events: all;
      transition: $popup-transition-speed;

      .popup-box {
        transform: scale(1);
        transition: $popup-transition-speed;
        opacity: 1;
        padding: 60px;

        .popup-title {
          margin-top: 5px;
          margin-bottom: 40px;
        }
      }

      .popup-backdrop {
        transition: $popup-transition-speed;
        opacity: 1;
      }
    }
  }

  &-box {
    padding: 80px;
    text-align: center;
    background: white;
    border-radius: 5px;
    z-index: 1;
    position: relative;
    transform: scale(0);
    opacity: 0;
    transition: $popup-transition-speed;
    margin: 20px;
  }

  &-title {
    margin-top: 15px;
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 60px;
  }

  &-buttons {
    font-size: 24px;
  }

  &-close {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    transform: rotate(45deg);
    opacity: 0.5;
    transition: 0.3s;
    width: 3em;
    height: 3em;
    background-size: 1.7em;
    display: block;
    cursor: pointer;

    &:hover {
      opacity: 1;
      transition: 0.3s;
    }
  }

  // Clickable area to close popup
  &-backdrop {
    background: rgba($color-text, 0.6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: $popup-transition-speed;
  }

  @media (max-width: $breakpoint-medium) {
    &-box {
      padding: 50px;
    }

    &-buttons {
      .button {
        display: block;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .notifications-erp-popup {
    width: 1000px;
    padding: 20px;
    height: 500px;
    overflow: auto;
    overflow-y: auto;
    background-color: $color-light;
    border-radius: 5px;
    text-align: left;

    p {
      margin: 0;
    }
  }

  .notifications-popup {
    max-width: 100%;
    padding: 20px;
    overflow: visible;
    overflow-y: auto;
    background-color: $color-light;
    border-radius: 5px;
    text-align: left;

    p {
      margin: 0;
    }
  }
}

.popup.popup-large .popup-wrapper {
  .popup-box {
    flex-basis: 1400px;
    padding: 30px;
    text-align: left;

    @media (min-width: $breakpoint-medium) {
      height: 780px;
    }

    .tab-content {
      padding: 10px 0 0 0;
    }

    .tabs-header-children {
      margin-right: 0;
      padding-right: 0;
    }

    .popup-title {
      margin-bottom: 10px;
      margin-top: 0;
      text-align: center;
    }

    .field-container {
      margin-top: 10px;
    }

    .custom-button-holder {
      display: inline-block;

      button.custom-button {
        height: 38px;
        width: 150px;
        padding: 0;
        font-size: 18px;

        img {
          width: 28px;
          margin-right: 5px;
        }
      }
    }

    .input-group input,
    .input-group select,
    .input-group textarea,
    .input-group-radio {
      padding-left: 1em;
    }
  }
}

.notifications-grid {
  .popup-box {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 90%;

    .sms-notifications-popup {
      margin-bottom: 20px;
    }

    .popup-title {
      margin-bottom: 20px !important;
    }
  }
}

ul.custom-context-menu {
  position: fixed;
  z-index: 1000;
  top: 50px;
  left: 50px;
  width: 300px;
  background-color: white;
  box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7);

  li {
    padding: 4px 8px;
    cursor: pointer;
    font-size: 16px;
    height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .icon {
      margin-right: 20px;
    }

    span {
      vertical-align: super;
    }

    span.option-disabled {
      color: #aaa;
    }
  }

  li:hover {
    background-color: #eee;
  }
}

.panel-popup.popup-query {
  margin-bottom: 40px;
  text-align: left;
}

.panel-popup.route-details {
  display: flex;
  padding: 0;

  .left-panel {
    overflow-y: auto;
  }

  .leaflet-container {
    flex: 1;
  }
}

.panel-popup {
  .vignette-summary {
    table {
      th,
      td {
        padding: 1px 10px;
        font-size: 16px;
      }

      td:not(:first-child) {
        width: 30%;
      }
    }
  }

  .vignette-summary.details-scroll {
    overflow-x: auto;
    height: 400px;
  }
}

.panel-popup.delict-details {
  overflow-y: auto !important;

  .delict-summary {
    table {
      th,
      td {
        padding: 1px 10px;
        font-size: 16px;
      }

      td:not(:first-child) {
        width: 30%;
      }
    }
  }
}

.panel-popup.obligations-summary {
  overflow-y: auto !important;

  .payment-transactions-title {
    padding: 1px 10px;
    background: #777879;
    text-align: left;
    color: white;
    font-weight: bold;
    font-size: 16px;
  }

  .payment-transactions {
    padding-top: 0;
  }
}

.payments-popup {
  .popup-box {
    height: 750px;
  }
}

.obulist-popup {
  padding: 20px;

  .button-obulist {
    cursor: pointer;

    &:disabled {
      cursor: initial;
      background: $color-border;
    }
  }
}

.obu-list-number {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    // needed for clearing the spinners in firefox
    -moz-appearance: textfield;
  }
}

.shifts-report-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;

  .shifts-report {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .icon-shifts {
      font-size: 50px;
    }
  }
}

.edit-partners {
  height: 550px !important;
}

.bold {
  font-weight: bold;
}

.full-width {
  width: 100%;
}

.popup.popup-erp .popup-wrapper.is-visible .popup-box .panel-edit.edit-contracts {
  height: 470px;
}
