.title {
  text-align: center;
}

.title img {
  width: 250px;
}

.users-table-wrapper {
  background-color: #ffffff;
  padding: 30px 10px;
}

.profile-picture img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.css-14el2xx-placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #a9a9a990 !important;
}

.users-create-new {
  text-align: right;
  margin-bottom: 20px !important;
}

.name {
  display: flex !important;
  align-items: center;
}

.name div:last-child {
  margin-left: 10px;
  cursor: pointer;
}

.chip {
  display: table-cell !important;
}
