.hookup-page-container {
  height: 100%;
  padding: 15px 20px;
  background-color: #fbfbfb;
  color: #3f3a38;
  font-weight: 600;
  background-color: #ffffff;
}

.hookup-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: 'Mangueira', 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.hookup-form label {
  margin-bottom: 5px;
  color: #5a6774;
  font-size: 12px;
  font-weight: 400;
}

.hookup-form input {
  border-radius: 2px;
}

.hookup-form .input-group input,
.hookup-form .input-group select,
.hookup-form .input-group textarea,
.hookup-form .input-group-radio {
  font-size: 12px;
  font-weight: 400;
}
