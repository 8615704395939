.chat-table-wrapper table {
  /*min-width: 650px;*/
}

.chat-table-wrapper .chatSection {
  width: 100%;
  position: relative;
}

.chat-table-wrapper .headBG {
  background-color: #e0e0e0;
  position: relative;
}

.chat-search {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999;
}

.chat-table-wrapper .borderRight500 {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

/* width */
.chat-table-wrapper ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.chat-table-wrapper ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #b0b0b0;
  border-radius: 10px;
}

/* Handle */
.chat-table-wrapper ::-webkit-scrollbar-thumb {
  background: rgb(143, 143, 143);
  border-radius: 10px;
}

/* Handle on hover */
.chat-table-wrapper ::-webkit-scrollbar-thumb:hover {
  background: rgb(114, 113, 113);
}

.chat-table-wrapper .messageArea {
  overflow-y: scroll;
  height: 65vh;
}

.chat-box {
  border-bottom: 1px solid #e0e0e0 !important;
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start !important;
  align-content: stretch;
  align-items: flex-start !important;
  font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.chat-avatar {
  order: 1;
  flex: 1 1;
  align-self: auto;
  padding-right: 5px;
}

.chat-user {
  order: 2;
  flex: 5 1 auto;
  align-self: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.chat-user-line-one,
.chat-user-line-two {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
}

.chat-user-name {
  order: 1;
  flex: 3 1 auto;
  align-self: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #122045;
}

.chat-message-date {
  order: 2;
  flex: 1 1 auto;
  align-self: auto;
  text-align: right;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #b2b6bf;
}

.chat-last-message {
  order: 1;
  flex: 6 0 auto;
  align-self: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #5a6774;
}

.chat-unread-messages {
  order: 2;
  flex: 0 1 auto;
  align-self: auto;
  text-align: right;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #5a6774;
}

.message-box {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start !important;
  align-content: stretch;
  align-items: flex-start !important;
  font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.message-box .chat-avatar {
  flex: 0 1 auto;
}

.message-box .chat-user-name {
  font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #122045;
}

.message-box .chat-last-message {
  font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #5a6774;
}

.message-box-header {
  padding-left: 7px !important;
}

.message-box-header .chat-avatar {
  flex: 0 1;
  align-self: auto;
  padding-right: 5px;
}

.message-box-header .chat-user-name {
  flex: 0 1 auto;
}

.message-box-header .chat-message-date {
  flex: 0 1 auto;
  margin-left: 20px;
  line-height: 150%;
}

.message-textarea,
.message-textarea label {
  font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #b2b6bf;
}

ul.messageArea {
  display: flex;
  flex-direction: column;
}

.message-section {
  display: flex;
  justify-content: space-between;
  flex-direction: column !important;
  border-left: 1px solid #e0e0e0;
  min-height: 80vh;
}

.message-section ul {
  overflow-x: hidden;
}

.chat-message {
  word-wrap: break-word;
  white-space: initial;
  width: 100%;
  hyphens: auto;
  order: 1;
  flex: 6 0 auto;
  align-self: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #5a6774;
}
