.profile-page-container {
  padding: 15px 20px;
  background-color: #fbfbfb;
  color: #5a6774;
  font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.profile-page-container .label {
  color: #b2b6bf;
  font-weight: 400;
  font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.css-14el2xx-placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #a9a9a990 !important;
}

.profile-page-container .header {
  color: #122045;
  font-weight: 600;
  font-family: 'Mangueira', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 24px;
  margin-bottom: 10px;
}

.profile-page-container .profile-image img {
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 20px;
}

.profile-page-container .profile-btns {
  margin-right: 10px;
  width: auto;
  padding: 0px 20px;
}

.profile-data > div {
  margin-top: 20px;
}

.profile-data ul {
  padding-top: 10px;
}

.profile-data li {
  list-style: inside;
  padding-top: 3px;
  padding-left: 15px;
}
