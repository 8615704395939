@charset "UTF-8";

[data-icon]:before {
  font-family: 'digitoll-font' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'digitoll-font' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-plus:before {
  content: '\61';
}

.icon-pin:before {
  content: '\62';
}

.icon-phone:before {
  content: '\63';
}

.icon-cart:before {
  content: '\65';
}

.icon-calendar:before {
  content: '\66';
}

.icon-email:before {
  content: '\64';
}

.icon-chevron-down:before {
  content: '\67';
}

.icon-chevron-up:before {
  content: '\6a';
}

.icon-check:before {
  content: '\6b';
}

.icon-grid:before {
  content: '\6c';
}

.icon-easy:before {
  content: '\6d';
}

.icon-bookmark:before {
  content: '\6e';
}

.icon-key:before {
  content: '\6f';
}

.icon-trash-o:before {
  content: '\70';
}

.icon-facebook:before {
  content: '\72';
}

.icon-bars:before {
  content: '\74';
}

.icon-plus-circle:before {
  content: '\75';
}

.icon-export:before {
  content: '\76';
}

.icon-user-outline:before {
  content: '\71';
}

.icon-close:before {
  content: '\73';
}

.icon-info-circle:before {
  content: '\77';
}

.icon-pencil-square-o:before {
  content: '\68';
}

.icon-floppy-o:before {
  content: '\69';
}

.icon-angle-double-left:before {
  content: '\78';
}

.icon-angle-double-right:before {
  content: '\79';
}

.icon-angle-left:before {
  content: '\7a';
}

.icon-angle-right:before {
  content: '\41';
}

.icon-search:before {
  content: '\42';
}

.icon-cloud-download:before {
  content: '\43';
}

.icon-share-square-o:before {
  content: '\44';
}

.icon-file-text-o:before {
  content: '\45';
}

.icon-cloud-upload:before {
  content: '\49';
}

.icon-truck:before {
  content: '\47';
}

.icon-gps:before {
  content: '\48';
}

.icon-banknote:before {
  content: '\4b';
}

.icon-contract:before {
  content: '\4a';
}

.icon-print:before {
  content: '\46';
}

.icon-car:before {
  content: '\4c';
}

.icon-back:before {
  content: '\4e';
}

.icon-diff-added:before {
  content: '\50';
}

.icon-chain-broken:before {
  content: '\51';
}

.icon-shuffle:before {
  content: '\4d';
}

.icon-refresh:before {
  content: '\4f';
}

.icon-magic:before {
  content: '\53';
}

.icon-bell:before {
  content: '\54';
}

.icon-bell-o:before {
  content: '\55';
}

.icon-organization:before {
  content: '\52';
}

.icon-file-text:before {
  content: '\56';
}

.icon-credit-card-1:before {
  content: '\58';
}

.icon-book:before {
  content: '\5a';
}
